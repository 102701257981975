.template-card {
	height: 260px;
	width: 300px;
	margin: 20px 0 0 20px !important;
	//display: flex;
	padding: 16px !important;
	overflow: hidden;
	flex: 0 0 auto;

	display: flex;
	flex-direction: column;
	&.centered {
		justify-content: center;
	}
	.icon-side {
		//width: 100px;
		//background: $primary;
		color: black;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: 70px;
		flex: 0 0 auto;
		img {
			width: 70px;
			height: 70px;
			object-fit: contain;
		}
	}
	.template-body {
		margin-top: 16px;
		color: black;
		.title {
			font-size: 18px;
			text-align: center;
		}
		.description {
			margin-top: 16px;
			opacity: 0.7;
			line-height: 1.2;
		}
	}
}

.template-wrap {
	display: flex;
	flex-wrap: wrap;
	width: 980px;
	max-width: 100%;
	@media (min-width: 576px) {
		width: 340px;
	}
	@media (min-width: 768px) {
		width: 660px;
	}
	@media (min-width: 1036px) {
		width: 980px;
	}
	@media (min-width: 1356px) {
		width: 1300px;
	}
	margin: 0 auto;
	padding-bottom: 20px;
	padding-right: 20px;
}

@media only screen and (max-width: 767px) {
	.ui.modal.template-modal > .content {
		padding: 0 !important;
	}
}
.template-modal {
	.content {
		background: #f5f5f5 !important;
	}

	.header > div {
		margin-left: 10px;
		font-weight: 400;
	}
}
