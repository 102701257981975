$fallback: 95%;
$padds: 28px * 2;

.insert-wrap {
	width: $fallback !important;
	width: calc(100% - 56px) !important;
	height: $fallback !important;
	height: calc(100% - 56px) !important;
	margin: 0 !important;
	margin-top: ($padds / 2 - 14px) !important;
	&.visible.transition {
		display: flex !important;
	}
	display: flex !important;
	flex-direction: column;
	.header {
		flex: 0 0 auto;
		padding: 5px !important;
		display: flex !important;
		align-items: center;
	}
	.content {
		flex: 1;
		overflow: hidden;
		padding: 0 !important;
		height: 100%;
		iframe {
			border: none !important;
			width: 100%;
			height: 100%;
		}
	}
}
