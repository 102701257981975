$screen_width: 360px;
$screen_extra: 63px;
$screen_ratio: 478 / (281 - 63);
$screen_height: ($screen_width - 2) / $screen_ratio - 1;
.screen-wrap {
	width: $screen_width!important;
	height: $screen_height + $screen_extra;
	background-image: url(/images/screen.svg);
	background-size: 100%;
	background-repeat: no-repeat;
	margin: 10px auto;
	overflow: hidden;
	padding: 2px;
	.screen {
		width: 100%;
		height: $screen_height;
		background-color: #f8f9ff;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		flex-wrap: wrap;
		overflow: hidden;
	}
	.screen-item {
		cursor: pointer;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
		padding: 10px;
		border-left: 1px solid #e7e9ef;
		border-bottom: 1px solid #e7e9ef;
		&.disabled {
			cursor: not-allowed;
			.item-wrap{

			opacity: 0.4;
			}
		}
		.item-wrap {
			&.selected {
				&:before {
					content: '';
					width: 12px;
					height: 12px;
					position: absolute;
					background-image: url(/images/radio-button.svg);
					background-size: 100%;
					top: -2px;
					right: -2px;
				}
				position: relative;
				border-color: #3569e7;
				background: #fff;
				color: #3569e7;
				font-weight: 600;
			}
			height: 100%;
			font-size: 13px;
			color: #393a3d;
			border-radius: 8px;
			background: 0 0;
			transition: all 0.2s ease-in-out;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;
		}
	}
}
