.pretext,
.posttext {
	margin: 0 10px 0 0;
	line-height: 38px;
	min-width: 200px;
	width: 200px;
}

.sub-section {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}

.filter-wrap {
	h3 {
		margin: 0 0 0 20px;
	}
	margin: 10px 0 50px 0;
	@media screen and (max-width: $mobile) {
		padding: 14px 6px 6px 6px !important;
	}
}

.css-selector {
	display: flex;
	align-items: center;
	margin-left: 20px;
	input {
		width: 600px;
		height: 36px;
	}
	.button {
		margin-left: 5px;
	}
	.css-current {
		flex: 1;
		overflow: auto;
		margin-left: 48px;
		white-space: nowrap;
		display: inline-block;
		margin-right: 12px;
		opacity: 0.7;
		font-size: 12px;
	}
}

.form-gen-wrap {
	display: flex;
	flex-wrap: wrap;
	.form-gen-title {
		font-weight: 600;
		width: 100%;
	}
	&.type-boxselect {
		.form-gen-wrap,
		.sub-section {
			flex: 1 1 100%;
		}
	}
	&.type-boolean {
		flex-wrap: nowrap;
		align-items: center;
		.form-gen-title {
			flex: 0;
			margin-right: 10px;
		}
	}

	&.vertical {
		.sub-section {
			width: 100%;
		}
	}
	/* .ui.horizontal.divider {
		flex: 1;
	} */
}
.popup-wrap {
	display: flex;
	flex: 1 1 auto;
	overflow: auto;
	@media (max-width: $mobile) {
		flex-wrap: wrap;
	}

	.left-section {
		flex: 1;
		display: flex;
		flex-direction: column;
		max-width: 480px !important;
		margin: 0;
		padding: 0;
		overflow: auto;
		.item-list {
			flex-wrap: wrap;
			padding: 7px 14px;
			.description {
				display: block;
				font-weight: 200;
			}
			> .segment {
				flex: 1;
				margin: 0 0 10px 0 !important;
				padding: 0 !important;
				img {
					max-height: 60px;
					height: 60px;
					margin: 0 !important;
					margin-right: 10px !important;
					border-radius: 3px 0 0 3px;
				}
				display: flex;
				align-items: center;

				flex-wrap: wrap;
				.description {
					width: 100%;
					padding: 6px 12px;
					opacity: 0.8;
					font-size: 0.875em;
				}
				cursor: pointer;
				.selected {
					font-weight: 600;
				}
				.button {
					margin-left: auto;
					margin-right: 8px;
				}
			}
		}
		.title-bar {
			flex: 0 0 auto;
		}
		.scroll-body {
			flex: 1 1;
			overflow-y: auto;
		}
		.content {
			padding: 7px 14px;
			.divider {
				margin-left: -14px;
				margin-right: -14px;
			}
		}
		.title {
			flex: 0 0 auto;
		}
		.card .content {
			padding-bottom: 0.5em !important;
		}
		@media (max-width: $mobile) {
			max-width: 100% !important;
		}
	}
	margin-left: -20px;
	.form-gen-wrap {
		width: 100%;
		> div {
			width: 100%;
			max-width: 100% !important;
		}
		.input {
			width: 100%;
		}
	}
}

.form-vertical {
	.form-gen-wrap {
		width: 100%;
		.input {
			width: 100%;
		}
	}
}

.trigger-wrap {
	.form-gen-wrap {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	padding-bottom: 20px;
	.pretext,
	.posttext {
		line-height: 42px;
	}
	.body {
		display: flex;
		@media screen and (max-width: $mobile) {
			flex-wrap: wrap;
			&::after {
				content: '';
				flex: 1;
				margin: 16px;
			}
		}
	}

	.sub-section {
		width: 100%;
	}

	.subs {
		display: flex;
		align-items: center;
		> span {
			color: white;
			border-radius: 3px;
			background: $primary;
			width: 24px;
			height: 24px;
			min-width: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 15px;
			margin-right: 5px;
		}
	}
}

.mini-box {
	.icon-container {
		width: 20px !important;
		height: 20px !important;
	}
	.box-select {
		height: 30px;
		padding: 0 4px;
	}
	.name-container {
		font-size: 16px !important;
	}
}

.box-select {
	&.active {
		color: $primary;
		border: 2px solid currentColor;
		.name-container {
			color: #333;
		}
	}
	border: 2px solid #d5d5d3;
	color: #93a4af;
	border-radius: 6px;
	display: flex;
	align-items: center;
	margin-right: 10px;
	padding: 10px;
	background: white;
	width: 200px;
	height: 80px;
	cursor: pointer;
	transition: color 0.1s ease, border 0.1s ease;

	.icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 56px;
		height: 56px;
		background: currentColor;
		transition: color 0.1s ease, background 0.1s ease;
		border-radius: 4px;
		i {
			color: white;
		}
	}
	.name-container {
		flex: 1;
		padding: 10px;
		font-size: 17px;
		transition: color 0.2s, background 0.2s;
		font-weight: 500;
		color: #93a4af;
	}

	@media screen and (max-width: $mobile) {
		flex: 0 0 auto;
		height: 56px;
		flex: 1;
		margin-bottom: 10px;
		.icon-container {
			width: 32px;
			height: 32px;
			i.big {
				font-size: 1rem !important;
			}
		}
		.name-container {
			font-size: 14px;
		}
	}
}

.box-wrap {
	display: flex;

	width: 100%;
	@media screen and (max-width: $mobile) {
		flex-wrap: wrap;
		&::after {
			content: '';
			flex: 1;
			margin: 16px;
		}
	}
}

.missing-line {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 2;
	.content .header {
		padding: 0 !important;
	}
}

.line-wrap {
	display: flex;
	flex: 1;
	align-items: flex-start;
	@media screen and (max-width: $mobile) {
		flex-wrap: wrap;
		&.sectionwrap {
			border: 1px solid #93a4af;
			border-radius: 3px;
			padding: 0 5px;
			margin-bottom: 5px;
		}
	}
}

.yt-datetime {
	line-height: 20px;
	margin: 9px 10px 9px 0;
	position: relative;
}

.yt-button {
	display: flex !important;
	align-items: center;
	align-self: center;

	@media screen and (max-width: $mobile) {
		margin-bottom: 5px !important;
	}
}

.action-form-wrap {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	> .segment {
		flex: 1;
		min-width: 400px;
		@media screen and (max-width: $mobile) {
			min-width: 100%;
		}
		max-width: 50%;
		margin: 0 0 10px 0 !important;
		padding: 0 !important;
		img {
			max-height: 60px;
			height: 60px;
			margin: 0 !important;
			margin-right: 10px !important;
			border-radius: 3px 0 0 3px;
		}
		display: flex;
		align-items: center;
		cursor: pointer;
		&:hover {
			.title .icon {
				opacity: 1;
			}
		}
		.title {
			font-weight: 600;
			font-size: 16px;
			color: #555;
			.icon {
				opacity: 0;
				transition: opacity 0.2s;
				position: absolute;
				top: 18px;
				right: 5px;
			}
		}
		.description {
			font-size: 12px;
			opacity: 0.6;
		}
	}
}

.action-form-out-wrap {
	margin: 10px 20px;
	padding-top: 20px;
	> h3 {
		margin: 0 0 0 10px !important;
	}
	@media screen and (max-width: $mobile) {
		margin: 10px;
	}
}

.yt-tab {
	width: 100%;
	> .segment {
		border: 0;
		padding: 0;
	}
}

.form-gen-wrap.one-line {
	&.double {
		.form-gen-title {
			width: 120px;
		}
	}
	.form-gen-title {
		width: 80px;
		display: inline-block;
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 10px !important;
	}
	height: 40px;
}

.error-pre {
	border: 2px solid #fc6e51;
	border-radius: 3px;
	margin: 10px 0 0 0;
	padding: 10px;
	max-width: 100%;
	white-space: pre;
	display: block;
}

.form-fix {
	.input {
		width: 100%;
	}
	> div {
		flex: 1 1 100%;
	}
}
