.product-select {
	&.mini {
		.item {
			width: 240px;
			height: 120px;
		}
		.icon-wrap img {
			width: 36px !important;
			height: 36px !important;
		}
	}
	display: flex;
	flex-wrap: wrap;
	.item {
		&:hover {
			transform: scale(1.05);
		}
		overflow: hidden;
		transition: transform 0.3s, width 0.3s, height 0.3s, margin 0.3s;
		.icon-wrap {
			flex: 1;
			height: 70px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&.fullicon {
				img {
					width: auto !important;
				}
			}
			img {
				width: 70px;
				height: 70px;
				object-fit: contain;
				border-radius: 6px;
			}
		}
		hr {
			border: 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			margin: 0;
			width: 100%;
		}
		.body {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex: 1;
		}
		.description {
			margin-top: 8px;
			font-size: 12px;
		}
		color: white;
		font-size: 24px;
		flex: 0 0 auto;
		width: 171px;
		height: 224px;
		margin: 0 16px 12px 0;
		display: flex;
		border-radius: 3px;
		flex-direction: column;
		align-items: center;
		user-select: none;
	}
}
