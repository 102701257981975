$primary: #2185d0;
$secondary: #00b5ad;
$orange: #f2711c;

$mobile: 767px;
$tablet: 1050px;

@import './layout', './editor', './screen', './insert', './template', './parts', './gradients';

html {
	-webkit-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
	margin-left: 90px;
	@media screen and (max-width: $tablet) {
		margin-left: 57px !important;
	}

	display: flex;
	flex-direction: column;
	max-height: 100%;
	height: 100%;
}

.d-flex {
	display: flex;
}

.custom-html {
	flex: 1;
	border: 0;
}

.mt-auto {
	margin-top: auto;
}

.ml-auto {
	margin-left: auto;
}

.ui.tiny.input {
	input {
		padding: 8px 12px;
		font-size: 14px;
	}
}

.console-logo {
	height: 55px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5 !important;
	border-radius: 0 !important;
	img {
		max-width: 100%;
		max-height: 100%;
	}
	&:hover {
		background-color: #f5f5f5 !important;
	}
	cursor: initial;
	@media screen and (max-width: $mobile) {
		height: 55px !important;
	}
}

.settings {
	max-width: 100%;
	overflow: auto hidden;
}

.ui.menu.pointing.secondary {
	.item.active {
		border-color: $orange !important;
		color: $orange !important;
	}
}

#root {
	height: 100%;
}
.dimmer {
	&.inverted {
		background-color: rgba(255, 255, 255, 0.2) !important;
	}
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.react-datepicker-popper {
	z-index: 5 !important;
}

.d-none {
	display: none !important;
}

.progress .bar {
	min-width: 80px !important;
}

.react-datepicker-wrapper {
	white-space: nowrap;
	width: 100%;
}
.ui.cards {
	justify-content: center !important;
}

#notfound {
	position: relative;
	height: 100vh;
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 920px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
}

.notfound .notfound-404 {
	position: absolute;
	height: 100px;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: -1;
}

.notfound .notfound-404 h1 {
	font-family: 'Maven Pro', sans-serif;
	color: #ececec;
	font-weight: 900;
	font-size: 276px;
	margin: 0px;
	position: absolute;
	user-select: none;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound h2 {
	font-family: 'Maven Pro', sans-serif;
	font-size: 46px;
	color: #000;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0px;
}

.notfound p {
	font-family: 'Maven Pro', sans-serif;
	font-size: 16px;
	color: #000;
	font-weight: 400;
	text-transform: uppercase;
	margin-top: 15px;
}

.notfound a {
	font-family: 'Maven Pro', sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

.notfound a:hover {
	background-color: #fff;
	border-color: #189cf0;
	color: #189cf0;
}

@media only screen and (max-width: 480px) {
	.notfound .notfound-404 h1 {
		font-size: 162px;
	}
	.notfound h2 {
		font-size: 26px;
	}
}

.ui.form .field .yt-input input:-webkit-autofill {
	border-bottom: none !important;
	border-radius: 3px 3px 0 0 !important;
}

.yt-line {
	&.active {
		&::after {
			transform: scaleX(1);
		}
		//border-bottom: 2px solid $primary;
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: -2px;
		left: 0;
		height: 2px;
		width: 100%;
		background: #c8ccd4;
		z-index: 2;
		background: $primary;
		transform: scaleX(0);
		transform-origin: 0 0;
		transition: all 0.15s ease;
	}
	border-bottom: 2px solid #c8ccd4;
}
.yt-input {
	width: 100%;
	&.black-placeholder {
		margin-bottom: 0 !important;
		input::placeholder {
			color: #333 !important;
		}

		&.error input::placeholder {
			color: #9f3a38 !important;
		}
	}
	input {
		height: 30px;
		margin-bottom: 2px;
		max-height: 100%;
		line-height: 3;
	}
	.icon {
		width: 26px !important;
	}
	input:focus {
		background: none;
		outline: none;
	}
	input:focus + span {
		transform: scaleX(1);
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		width: 100%;
		background: #c8ccd4;
		z-index: 2;
	}
	&.secondary input + span {
		background: $secondary;
	}
	input + span {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		z-index: 3;
		width: 100%;
		background: $primary;
		transform: scaleX(0);
		transform-origin: 0 0;
		transition: all 0.15s ease;
	}
}

.flex-0-0 {
	flex: 0 0 auto !important;
}

.type-cont {
	margin: 5px 0;
}
.type-span {
	display: inline-block;
	width: 80px;
	margin-right: 5px;
}

$lineHeight: 30px;
$lineWidth: 15px;
.tree-view {
	label,
	.label {
		text-align: center;
		position: relative;
	}
	.input {
		flex: 1;
	}
	li {
		&::before {
			content: '';
			position: absolute;
			width: $lineWidth;
			height: $lineHeight;
			border-left: 1px solid;
			border-bottom: 1px solid;
			border-color: rgba(0, 0, 0, 0.3);
			left: -1 * $lineWidth;
			top: (-1/2) * $lineHeight;
		}
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
	}
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	padding-left: $lineWidth + 4;
}

.phone-preview-wrap {
	.inner-wrap {
		.browser-head {
			background: #ffffff;
			border-bottom: 1px solid rgba(34, 36, 38, 0.15);
			height: 44px;
			display: flex;
			align-items: center;
			padding-left: 20px;
			border-radius: 5px 5px 0 0;
			.header {
				margin: 0 !important;
			}
			.dropdown {
				padding: 10px;
				min-height: 36px;
				margin-right: 4px;
			}
		}
		iframe {
			border-radius: 0 0 5px 5px;
		}
		flex: 1;
		display: flex;
		height: 100%;
		border: 1px solid rgba(34, 36, 38, 0.15);
		border-radius: 5px;
		flex-direction: column;
	}
	&.mobile {
		iframe {
			width: 414px;
			height: 736px;
			flex: 0 0 auto;
			border-radius: 3px;
			margin: auto 0;
			align-self: center;

			background: #eff1f5;
			border: 1px solid $primary !important;
		}

		.flex-wrap {
			flex: 1;
			display: block;
			text-align: center;
			overflow: hidden;
		}
	}

	.flex-wrap {
		flex: 1;
		overflow: hidden;
	}

	flex: 1;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: $mobile) {
		display: none !important;
	}
}

.full-size {
	width: 100%;
	height: 100%;
}

.no-border {
	border: none !important;
}

.no-wrap {
	white-space: nowrap;
}

.layout-header .th.large.icon {
	font-size: 1em !important;
}

.header-seperator {
	& + .header-item::before {
		display: none;
	}
	height: 2px;
	background: $orange;
}

.breadcrumb {
	height: 40px;
	display: flex !important;
	align-items: center;

	.section {
		color: inherit !important;
		opacity: 0.8;
		transition: opacity 0.2s ease;
		display: flex !important;
		align-items: center;
		.icon {
			margin-right: 5px;
		}
		&:hover {
			opacity: 1;
		}
		.description {
			font-size: 12px;
			margin-left: 30px;
			opacity: 0.7;
		}
	}
}

.ref-span {
	position: absolute;
	left: 50%;
	top: 0;
}

.cursor-pointer {
	cursor: pointer !important;
}

.action-edit-wrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	background: inherit;
	padding: 20px;
	display: flex;
	background: white;
	> .sub-section {
		flex-wrap: nowrap;
	}
	> div {
		flex: 1;
		display: flex;
		flex-direction: column;
		max-width: 100%;
	}

	@media (max-width: $mobile) {
		padding: 10px;
	}
}

@media screen and (max-width: $mobile) {
	.mobile-none {
		display: none !important;
	}
}

a:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

.fill {
	width: 100%;
}

.ml-auto {
	margin-left: auto !important;
}

.intl-tel-input {
	outline: none !important;
	.selected-flag {
		outline: none !important;
	}
	input {
		outline: none;
		height: 40px;
		border-color: none;
		font-size: 14px;
		height: 36px;
		font-size: 14px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px 0 0 4px !important;
		width: 250px;
	}
}
.left-menu {
	.item {
		display: block;
		line-height: 2rem !important;
		position: relative;
		font-size: 14px !important;
	}
	.ui.vertical.menu {
		height: 120% !important;
		min-height: 100% !important;
	}
}
.mi-btn {
	background-color: transparent;
	color: #000;
	border: none;
	line-height: 0;
	opacity: 0.7;
	padding: 8px 4px;
	outline: none !important;
	-webkit-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
}
.arrayBox {
	clear: both;
	white-space: nowrap;
	overflow: auto;
	overflow-y: hidden;
	overflow-x: auto;
	display: block;
	padding-bottom: 0.75rem;
	height: 3.25rem;
	min-height: 3.25rem;
	margin-top: 0.5rem;
	padding-top: 0.25rem;
	position: relative;
	opacity: 1;
	animation: display-none-transition 1s both;
	.chatarray {
		background: #fff;
		font-size: 1rem;
		padding: 0 0.75rem;
		margin: 0 0.25rem;
		line-height: 2rem;
		height: 2rem;
		color: #4f93ce;
		border-radius: 1rem;
		border: 1px solid #aaa;
		cursor: pointer;
		display: inline-block;
	}
}

.phone-screen {
	display: flex;
	align-items: center;
	.phone-line {
		display: flex;
		align-items: center;
		flex: 1;
		color: black;
		height: 30px;
		padding: 1.5em 0.5em;
	}
	.phone-id {
		width: 80px;
	}
	.phone-number {
		width: 150px;
	}
	.phone-status {
		margin-right: 20px;
		margin-left: auto;
		white-space: nowrap;
	}
}
.phone-progress {
	margin: 0 !important;
	.bar {
		border-radius: 0 !important;
	}
	.label {
		font-size: 10px !important;
		font-weight: 300 !important;
		position: absolute !important;
		top: 0 !important;
		height: 100% !important;
		margin: 0 !important;
		line-height: 13px !important;
		text-transform: uppercase !important;
	}
	border-radius: 0 !important;
}

.host-not-found {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.myLabel {
	margin-right: 5px;
	padding: 6px 12px;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(204, 204, 204);
	border-image: initial;
	border-radius: 3px;
	background-color: #ffffff;
}

#dahiWrap {
	line-height: 1;
}
#dahiWrap div,
#dahiWrap span,
#dahiWrap iframe,
#dahiWrap input,
#dahiWrap img,
#dahiWrap svg {
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto', Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
	font-size-adjust: none;
	font-size: 100%;
	font-style: normal;
	letter-spacing: normal;
	font-stretch: normal;
	font-variant: normal;
	font-weight: 400;
	font: normal normal 100% -apple-system, BlinkMacSystemFont, 'Roboto', Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
	text-align: left;
	-moz-text-align-last: auto;
	text-align-last: auto;
	text-decoration: none;
	-webkit-text-emphasis: none;
	text-emphasis: none;
	text-height: auto;
	text-indent: 0;
	text-justify: auto;
	text-outline: none;
	text-shadow: none;
	text-transform: none;
	text-wrap: normal;
	alignment-adjust: auto;
	alignment-baseline: baseline;
	-webkit-animation: none 0 ease 0 1 normal;
	animation: none 0 ease 0 1 normal;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	-webkit-appearance: normal;
	-moz-appearance: normal;
	appearance: normal;
	azimuth: center;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	background: none 0 0 auto repeat scroll padding-box transparent;
	background-color: transparent;
	background-image: none;
	baseline-shift: baseline;
	binding: none;
	bleed: 6pt;
	bookmark-label: content();
	bookmark-level: none;
	bookmark-state: open;
	bookmark-target: none;
	border: 0 none transparent;
	border-radius: 0;
	bottom: auto;
	box-align: stretch;
	-webkit-box-decoration-break: slice;
	box-decoration-break: slice;
	box-direction: normal;
	box-flex: 0;
	box-flex-group: 1;
	box-lines: single;
	box-ordinal-group: 1;
	box-orient: inline-axis;
	box-pack: start;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-column-break-after: auto;
	-moz-column-break-after: auto;
	break-after: auto;
	-webkit-column-break-before: auto;
	-moz-column-break-before: auto;
	break-before: auto;
	-webkit-column-break-inside: auto;
	-moz-column-break-inside: auto;
	break-inside: auto;
	caption-side: top;
	clear: none;
	clip: auto;
	color: inherit;
	color-profile: auto;
	-webkit-column-count: auto;
	-moz-column-count: auto;
	column-count: auto;
	-webkit-column-fill: balance;
	-moz-column-fill: balance;
	column-fill: balance;
	-webkit-column-gap: normal;
	-moz-column-gap: normal;
	column-gap: normal;
	-webkit-column-rule: medium medium #1f1f1f;
	-moz-column-rule: medium medium #1f1f1f;
	column-rule: medium medium #1f1f1f;
	-webkit-column-span: 1;
	-moz-column-span: 1;
	column-span: 1;
	-webkit-column-width: auto;
	-moz-column-width: auto;
	column-width: auto;
	-webkit-columns: auto auto;
	-moz-columns: auto auto;
	columns: auto auto;
	content: normal;
	counter-increment: none;
	counter-reset: none;
	crop: auto;
	cursor: auto;
	direction: ltr;
	display: inline;
	dominant-baseline: auto;
	drop-initial-after-adjust: text-after-edge;
	drop-initial-after-align: baseline;
	drop-initial-before-adjust: text-before-edge;
	drop-initial-before-align: caps-height;
	drop-initial-size: auto;
	drop-initial-value: initial;
	elevation: level;
	empty-cells: show;
	fit: fill;
	fit-position: 0 0;
	float: none;
	float-offset: 0 0;
	grid-columns: none;
	grid-rows: none;
	hanging-punctuation: none;
	height: auto;
	hyphenate-after: auto;
	hyphenate-before: auto;
	hyphenate-character: auto;
	hyphenate-lines: no-limit;
	hyphenate-resource: none;
	-webkit-hyphens: manual;
	-ms-hyphens: manual;
	hyphens: manual;
	icon: auto;
	image-orientation: auto;
	image-rendering: auto;
	image-resolution: normal;
	inline-box-align: last;
	left: auto;
	line-height: inherit;
	line-stacking: inline-line-height exclude-ruby consider-shifts;
	list-style: disc outside none;
	margin: 0;
	marks: none;
	marquee-direction: forward;
	marquee-loop: 1;
	marquee-play-count: 1;
	marquee-speed: normal;
	marquee-style: scroll;
	max-height: none;
	max-width: none;
	min-height: 0;
	min-width: 0;
	move-to: normal;
	nav-down: auto;
	nav-index: auto;
	nav-left: auto;
	nav-right: auto;
	nav-up: auto;
	opacity: 1;
	orphans: 2;
	outline: medium none invert;
	outline-offset: 0;
	overflow: visible;
	overflow-style: auto;
	padding: 0;
	page: auto;
	page-break-after: auto;
	page-break-before: auto;
	page-break-inside: auto;
	page-policy: start;
	-webkit-perspective: none;
	perspective: none;
	-webkit-perspective-origin: 50% 50%;
	perspective-origin: 50% 50%;
	pointer-events: auto;
	position: static;
	presentation-level: 0;
	punctuation-trim: none;
	quotes: none;
	rendering-intent: auto;
	resize: none;
	right: auto;
	rotation: 0;
	rotation-point: 50% 50%;
	ruby-align: auto;
	ruby-overhang: none;
	ruby-position: before;
	ruby-span: none;
	size: auto;
	string-set: none;
	table-layout: auto;
	top: auto;
	-webkit-transform: none;
	transform: none;
	-webkit-transform-origin: 50% 50% 0;
	transform-origin: 50% 50% 0;
	-webkit-transform-style: flat;
	transform-style: flat;
	-webkit-transition: all 0 ease 0;
	transition: all 0 ease 0;
	unicode-bidi: normal;
	vertical-align: baseline;
	white-space: normal;
	white-space-collapse: collapse;
	widows: 2;
	width: auto;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	z-index: auto;
	text-align: start;
	-ms-filter: 'progid:DXImageTransform.Microsoft.gradient(enabled=false)';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#dahiWrap .dahi-transparent-frame {
	z-index: 2147483649;
	position: fixed !important;
	max-height: calc(100% - 20px + 10px) !important;
	width: 360px !important;
}
#dahiWrap.not-solid .dahi-chat-cont.dahi-int {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	min-height: auto;
}
#dahiWrap .dahi-wa-icon {
	transition: opacity 0.2s ease;
	opacity: 1;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	text-decoration: none !important;
}
#dahiWrap .dahi-wa-icon:hover {
	text-decoration: none !important;
}
#dahiWrap .dahi-wa-icon:hover .dahi-wa-close {
	opacity: 0.9 !important;
}
#dahiWrap .dahi-wa-icon .dahi-wa-title {
	word-break: break-all;
	background: white;
	margin: 5px;
	padding: 5px 20px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 180px;
	text-align: center;
	color: #333;
	box-shadow: 0 2px 4px 0 rgba(0, 18, 46, 0.16);
	text-decoration: none !important;
}
#dahiWrap .dahi-wa-icon .dahi-wa-title .dahi-wa-close:hover {
	opacity: 1 !important;
}
#dahiWrap .dahi-wa-icon svg {
	width: 52px;
	height: 52px;
}
#dahiWrap .dahi-wa-icon:hover {
	opacity: 1;
}
#dahiWrap.dahi-flormarweb.dahi-mobile.dahi-open .dahi-widget-cont {
	display: flex !important;
}
#dahiWrap.dahi-flormarweb .dahi-mobile-frame.not-solid {
	bottom: 55px !important;
}
#dahiWrap.dahi-flormarweb.dahi-open .dahi-widget-cont {
	display: none !important;
}
#dahiWrap.dahi-flormarweb .dahi-widget-cont {
	width: 40px !important;
	height: 40px !important;
	right: 15px !important;
	bottom: 15px !important;
	box-shadow: none !important;
	border: 1px solid #f44a7e !important;
}
#dahiWrap.dahi-flormarweb .dahi-chat-cont.dahi-int {
	bottom: 0 !important;
	right: 110px !important;
	border-radius: 6px 6px 0 0 !important;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
}
#dahiWrap.dahi-flormarweb .dahi-shadow {
	display: none !important;
}
#dahiWrap .dahi-chat-cont {
	max-width: 100%;
	max-height: 100%;
	max-height: calc(100% - 10px);
	min-height: 240px;
	overflow: hidden;
	position: fixed;
	z-index: 2147483650;
	right: 20px;
	bottom: 0;
	display: block;
	box-shadow: 0 8px 26px 0 rgba(0, 18, 46, 0.16) !important;
	border-radius: 3px;
}
#dahiWrap .dahi-chat-cont.bottom-left {
	right: unset !important;
	left: 20px !important;
}
#dahiWrap .dahi-chat-cont.bottom-left.dahi-int {
	right: unset !important;
	left: 10px !important;
	bottom: 80px !important;
}
#dahiWrap .dahi-chat-cont.dahi-int {
	border-radius: 6px !important;
	bottom: 80px !important;
	max-height: calc(100% - 90px);
	right: 10px !important;
	transition: background-color 0.2s ease, height 0.2s ease, -webkit-box-shadow 0.2s ease;
	transition: background-color 0.2s ease, box-shadow 0.2s ease, height 0.2s ease;
	transition: background-color 0.2s ease, box-shadow 0.2s ease, height 0.2s ease, -webkit-box-shadow 0.2s ease;
}
#dahiWrap .dahi-chat-frame {
	border: 0;
	background-color: transparent;
	vertical-align: text-bottom;
	position: relative;
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
	display: block;
	border-radius: 3px;
}
#dahiWrap .dahi-fullscreen-frame,
#dahiWrap .dahi-mobile-frame {
	vertical-align: text-bottom;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 2147483647;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
	display: block;
	opacity: 1;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
}
#dahiWrap .dahi-fullscreen-frame.not-solid,
#dahiWrap .dahi-mobile-frame.not-solid {
	left: unset;
	right: 5px !important;
	top: unset;
	bottom: 60px !important;
	min-height: auto;
	min-width: auto;
	width: auto;
}
#dahiWrap.dahi-mobile .dahi-mobile-frame {
	z-index: 2147483646 !important;
}
#dahiWrap.bottom-left .dahi-interactive {
	bottom: 80px;
	left: 20px;
	right: unset;
}
#dahiWrap.bottom-left .dahi-mobile-frame.not-solid {
	right: unset !important;
	left: 5px;
}
#dahiWrap.bottom-left.toggle.dahi-mobile .dahi-widget-cont {
	top: unset !important;
	right: unset !important;
	left: 10px !important;
}
#dahiWrap.bottom-left .dahi-widget-cont {
	right: unset !important;
	left: 10px !important;
}
#dahiWrap.bottom-left .dahi-chat-cont {
	right: unset !important;
	left: 20px !important;
}
#dahiWrap.bottom-left .dahi-chat-cont.dahi-int {
	right: unset !important;
	left: 10px !important;
	bottom: 80px !important;
}
#dahiWrap.bottom-left .dahi-shadow {
	right: unset !important;
	left: 0 !important;
	background: radial-gradient(ellipse at bottom left, rgba(29, 39, 54, 0.16) 0, rgba(29, 39, 54, 0) 72%);
}
#dahiWrap.no-title.dahi-mobile.dahi-open .dahi-widget-cont {
	display: block !important;
	left: unset !important;
	right: 5px !important;
	bottom: unset;
	top: 5px;
}
#dahiWrap .dahi-shadow {
	z-index: 2147483640;
	position: fixed;
	width: 500px;
	height: 500px;
	bottom: 0;
	right: 0;
	content: '';
	pointer-events: none;
	background: radial-gradient(ellipse at bottom right, rgba(29, 39, 54, 0.16) 0, rgba(29, 39, 54, 0) 72%);
}
body .ins-preview-wrapper,
body .pushcrew-side-button-mobile,
body .pushcrew-mobile-chicklet-bottom,
body #_hj_feedback_container > div {
	z-index: 2147483646 !important;
}
.tost {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 70%;
	font-size: 12px;
	z-index: 2147483647;
	background: rgba(255, 255, 255, 0.7);
	min-height: 100px;
	overflow: auto;
	border: 1px solid red;
}
.dahi-widget iframe {
	border: 0;
}

.nosegment {
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
}

.table-hide {
	display: none;
}
.userdefinition {
	pointer-events: all;
}
.phone-side {
	@media (min-width: 1500) {
		.phone-list {
			width: 100% !important ;
		}
	}
}
.scroll {
	overflow-y: scroll;
	max-height: 85vh;
	min-height: 85vh;
	margin-top: 15px;
}

.shoplist {
	@media (min-width: 768px) {
		display: flex;
	}

	.pro-image {
		background-color: #fff;
		height: 50px;
		border-radius: 10px;
		width: 50px;
		overflow: hidden;
		img {
			object-fit: cover;
			width: 100%;
			height: 50px;
		}
	}
	.name {
		margin-left: 15px;
		text-align: left;
	}
	.list {
		background-color: #f4f6fb;
		padding: 30px 30px;
		height: 100vh;
		overflow: auto;
		overflow-x: hidden;
		width: 100%;
		.list-block {
			margin-bottom: 20px;
			.title {
				background: #323f47;
				margin: auto;
				padding: 15px;
				color: white;
				border-radius: 5px 5px 0px 0;
				font-size: 22px;
			}
			.product-block {
				border-radius: 10px;
				box-shadow: 0 0 10px 0 #7792b329;
				.product {
					display: flex;
					width: 100%;
					margin: 0 auto;
					grid-row-gap: 20px;
					background-color: white;
					padding: 10px;
					font-size: 16px;
					.name {
						width: 90%;
					}
				}
			}
		}
	}
	.orderlist {
		.close-modal {
			display: none;
		}
		@media (max-width: 768px) {
			&.noShow {
				display: none;
			}
			width: 100%;
			position: fixed;
			z-index: 127;
			bottom: 0;
			right: 0;
			left: 0;
			cursor: pointer;
			.close-modal {
				position: relative;
				right: -10px;
				top: -10px;
				width: 30px;
				float: right;
				display: block;
			}
		}
		background-color: #323f47;
		padding: 30px 30px;
		height: 100vh;
		overflow: auto;
		overflow-x: hidden;
		width: 600px;
		.title {
			color: white;
			font-size: 22px;
		}
		.address {
			background-color: white;
			padding: 20px 10px 20px 10px;
			border-radius: 10px;
		}
		.special {
			background-color: white;
			padding: 20px 10px 20px 10px;
			border-radius: 10px;
			margin-top: 20px;
		}
		.cart {
			background-color: white;
			border-radius: 10px;
			margin-top: 20px;
			text-align: left;
			.dive {
				border-bottom: 1px solid #eee;
			}
			.cart-block {
				padding: 10px;
				.cart-product {
					display: flex;
					margin-bottom: 25px;
					.count {
						margin-left: auto;
					}
				}
			}
			.cart-details {
				padding: 20px;
				font-size: 16px;
				.price-div {
					display: flex;
					margin-bottom: 5px;
					p:nth-child(1) {
						width: 40%;
					}
					p:nth-child(2) {
						width: 100%;
						text-align: right;
					}
					&.last {
						margin-bottom: 10px;
					}
				}
			}
			.cart-info {
				text-align: center;
				background-color: #f4f6fb;
				border-radius: 0 0 10px 10px;
				padding: 10px;
				p {
					margin-bottom: 1px;
				}
			}
		}
		.whatsapp {
			text-align: center;
			margin-top: 20px;
		}
	}
	.mobile-cart {
		display: none;
		@media (max-width: 768px) {
			display: block;
		}
		.fixd-btm-cart {
			display: flex;
			position: fixed;
			z-index: 99;
			bottom: 0;
			right: 0;
			left: 0;
			height: 58px;
			padding: 5px 15px !important;
			cursor: pointer;
			-webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.5);
			box-shadow: 0 0px 8px rgba(0, 0, 0, 0.5);
			background: #323f47;
			color: white;
		}
	}
}
.tag-list {
	width: 50%;
	border-right: 1px solid lightgray;
	padding: 0;
	margin: 0;
	.head {
		min-height: 40px;
		h3 {
			width: 100%;
			display: flex;
			align-items: center;
			margin: 0;
			margin-left: 20px;
		}
		.add-button {
			display: flex;
			align-items: center;
			margin: 0;
		}
		border-bottom: 1px solid lightgray;
	}
	.list-item {
		border-bottom: 1px solid lightgray;
		min-height: 40px;
		display: flex;
		align-items: center;
		cursor: pointer;
		:hover {
			font-weight: bold;
		}
	}
}
.tag-editor {
	width: 100%;
}

.tag-color {
	padding-top: 5px;
	padding-left: 5px;
	width: 392px;
	border-radius: 2px;
	background-color: #fff;
	border: 1px solid lightgray;
	margin-bottom: 30px;
}
.tag-color-colors {
	overflow: hidden;
	padding: 0;
	margin: 0;
}
.tag-color-color-item {
	list-style: none;
	width: 30px;
	height: 30px;
	float: left;
	margin-right: 5px;
	margin-bottom: 5px;
	position: relative;
	cursor: pointer;
}
.tag-color-color-item--white {
	box-shadow: inset 0 0 0 1px #ddd;
}
.tag-color-color-item--white .tag-color-dot {
	background: #000;
}
.tag-color-dot {
	position: absolute;
	top: 8px;
	right: 8px;
	bottom: 8px;
	left: 8px;
	border-radius: 50%;
	opacity: 1;
	background: #fff;
	width: 14px;
	height: 14px;
}
#react-time-range {
	margin: auto;
}
.react-time-picker__inputGroup {
	min-width: auto !important;
}
.react-time-picker__inputGroup__minute,
.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
	display: none;
}
.cl-labels label.label-tags {
	background-color: #fff;
	border: 1px solid currentColor;
	color: #4f93ce;
	line-height: 12px;
	margin: 0 5px 3px 0;
}
.cl-labels label {
	margin: 0 0 0 5px;
	font-size: 10px;
	background-color: #21a163;
	color: #fff;
	border-radius: 3px;
	padding: 0 5px;
	height: 14px;
	font-weight: 400;
	line-height: 14px;
	overflow: hidden;
}
.wi-90 {
	width: 90%;
}

.ac-card {
	position: relative;
}
.template-card-menu {
	border: none !important;
	box-shadow: none !important;
	margin-top: 0 !important;
	margin-bottom: 40px !important;
	overflow: hidden;
	flex: 0 0 auto;
	@media (max-width: 576px) {
		margin: 0 0 0 0 !important;
		width: 220px;
		&.centered {
			justify-content: center;
		}
	}
	@media (min-width: 576px) {
		width: 420px;
		display: flex;
		flex-direction: column;
		&.centered {
			justify-content: center;
		}
		.box {
			display: flex;
		}
	}
	@media (min-width: 768px) {
		width: 420px;
	}
	@media (min-width: 1036px) {
		width: 420px;
		margin: auto;
	}
	@media (min-width: 1356px) {
		width: 580px;
		margin: auto;
	}
	@media (min-width: 1756px) {
		width: 580px;
		margin-left: 60px !important;
	}
	.icon-side {
		@media (max-width: 768px) {
			color: black;
			flex: 0 0 auto;
			img {
				margin-top: 15px;
				width: 90px;
				object-fit: contain;
				margin-right: 15px;
			}
		}
		@media (min-width: 768px) {
			color: black;
			margin-top: 20px;
			img {
				margin-top: 15px;
				width: 90px;
				object-fit: contain;
				margin-right: 15px;
			}
		}
	}
	.template-body {
		margin-top: 16px;
		margin-bottom: 16px;
		color: black;
		.title {
			font-size: 18px;
		}
		.description {
			margin-top: 16px;
			opacity: 0.7;
			line-height: 1.2;
		}
	}
}

.templateSlider {
	.label {
		margin-bottom: 5px;
	}
	.template-images {
		max-height: 500px;
	}
	.long-description {
		font-size: 16px;
	}
	@media (max-width: 480px) {
		.text-side {
			h1 {
				font-size: 20px;
			}
		}
		.template-images {
			max-width: 250px;
			cursor: pointer;
			//width: 200px;
			box-shadow: 0 0 6px 1px #c4cdd5;
			margin: auto;
		}
		.long-description {
			font-size: 14px;
			margin-bottom: 30px;
		}
		.header-box {
			margin-left: 0;
			margin-right: 0;
		}
		.slick-initialized .slick-slide {
			padding: 20px 0 !important;
			/* display: block; */
		}
	}
	@media (min-width: 500px) {
		.template-images {
			max-width: 350px;
			//max-height: 245px;
			cursor: pointer;
			//width: 200px;
			box-shadow: 0 0 6px 1px #c4cdd5;
			margin: auto;
		}
		.long-description {
			margin-bottom: 30px;
			margin-left: 70px;
			margin-right: 70px;
		}
		.header-box {
			margin-left: 70px;
			margin-right: 70px;
		}
		.tags {
			margin-left: 70px;
			margin-right: 70px;
		}
		.slick-initialized .slick-slide {
			padding: 20px 0 !important;
			/* display: block; */
		}
	}
	@media (min-width: 576px) {
		.template-images {
			max-width: 350px;
			//max-height: 245px;
			cursor: pointer;
			//width: 400px;
			box-shadow: 0 0 6px 1px #c4cdd5;
			margin: auto;
		}
	}
	@media (min-width: 768px) {
		.template-images {
			max-width: 370px;
			//max-height: 245px;
			cursor: pointer;
			//: 500px;
			box-shadow: 0 0 6px 1px #c4cdd5;
			margin: auto;
		}
		.long-description {
			margin-left: 70px;
			margin-right: 70px;
			margin-bottom: 50px;
		}
		.header-box {
			margin-left: 70px;
			margin-right: 70px;
		}
		.tags {
			margin-left: 70px;
		}
	}
	@media (min-width: 1036px) {
		.template-images {
			max-width: 470px;
			//max-height: 245px;
			cursor: pointer;
			//width: 500px;
			box-shadow: 0 0 6px 1px #c4cdd5;
			margin: auto;
		}
		.long-description {
			margin-left: 70px;
			margin-right: 70px;
			margin-bottom: 50px;
		}
		.header-box {
			margin-left: 70px;
			margin-right: 70px;
		}
		.tags {
			margin-left: 70px;
		}
	}
	@media (min-width: 1356px) {
		.template-images {
			max-width: 560px;
			//max-height: 300px;
			cursor: pointer;
			//width: 560px;
			box-shadow: 0 0 6px 1px #c4cdd5;
			margin: auto;
		}
		.header-box {
			margin-left: 180px;
			margin-right: 180px;
		}
		.long-description {
			margin-left: 180px;
			margin-right: 180px;
			margin-bottom: 50px;
		}
		.tags {
			margin-left: 180px;
		}
	}
}

.header-box {
	display: flex;

	@media (max-width: 576px) {
		.image-side {
			margin: 10px 10px 10px 10px;
			text-align: center;
			img {
				width: 80px;
			}
		}
	}
	@media (min-width: 576px) {
		.image-side {
			margin: 10px 40px 10px 40px;
			img {
				width: 100px;
			}
		}
	}
	@media (min-width: 768px) {
		.image-side {
			margin: 10px 40px 10px 40px;
			img {
				width: 100px;
			}
		}
	}
	@media (min-width: 1036px) {
		.image-side {
			margin: 10px 40px 10px 40px;
			img {
				width: 100px;
			}
		}
	}
	@media (min-width: 1356px) {
		.image-side {
			margin: 10px 40px 10px 40px;
			img {
				width: 100px;
			}
		}
	}
}

.slick-next:before,
.slick-prev:before {
	color: #000 !important;
}

.slick-initialized .slick-slide {
	padding: 20px !important;
	display: block;
}

@media (max-width: 576px) {
	.only-web {
		display: none;
	}
}
@media (min-width: 575px) {
	.only-mobile {
		display: none;
	}
}
.pricing {
	padding: 15px;
	background-color: #f4f6f8;

	@media (min-width: 500px) {
		margin-bottom: 30px;
		margin-left: 70px;
		margin-right: 70px;
	}

	@media (min-width: 768px) {
		margin-left: 70px;
		margin-right: 70px;
	}
	@media (min-width: 1036px) {
		margin-left: 70px;
		margin-right: 70px;
	}
	@media (min-width: 1356px) {
		margin-left: 180px;
		margin-right: 180px;
	}
	.p-title {
		@media (min-width: 1036px) {
			margin-left: 30px;
			display: flex;
			div {
				margin-left: 10px;
				margin-top: auto;
				display: contents;
			}
		}
		margin-bottom: 10px;
	}
	.price-card-part {
		@media (min-width: 1036px) {
			display: flex;
		}
		.price-card {
			h2 {
				font-weight: 300;
				font-size: 18px;
			}
			h1 {
				font-weight: 400;
				font-size: 36px;
				span {
					font-size: 24px;
				}
			}
			@media (min-width: 1036px) {
				min-width: calc(25% - 30px);
				max-width: calc(25% - 30px);
			}
			padding: 30px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;
			overflow: hidden;
			background: #ffffff;
			box-shadow: 0 0 6px 1px #c4cdd5;
			border-radius: 6px;
			.extra {
				padding: 0;
				list-style: none;
				li {
					img {
						margin-right: 5px;
					}
					align-items: center;
					font-size: 18px;
					display: flex;
				}
				.check {
					height: 16px;
					font-size: 16px;
				}
			}
		}
	}
}

.platforms {
	@media (min-width: 576px) {
		display: flex;
	}

	.platform-vl {
		border-left: 1px solid rgba(34, 36, 38, 0.15);
		height: 500px;
		margin: auto;
		@media (max-width: 576px) {
			display: none;
		}
	}
	.platform-web,
	.platform-extension {
		@media (min-width: 576px) {
			width: 50%;
		}
		//box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
		padding: 15px 20px;
		margin: 5px;
		border-radius: 5px;
		cursor: pointer;
		.title {
			margin-top: 20px;
			font-size: 20px;
			font-weight: 700;
			span {
				font-weight: 400;
			}
		}
		.description {
			margin-top: 10px;
			margin-bottom: 10px;
			min-height: 200px;
			padding: 0 20px;
		}
		img {
			width: 100%;
			display: flex;
			border-radius: 3px;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
			//box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
		}
		&:hover {
			border-color: lightblue;
			.select-btn {
				color: white;
				background-color: #2185d0;
			}
		}
		.select-btn {
			display: flex;
			margin: auto;
			box-shadow: 0 0 0 1px #2185d0 inset !important;
			color: #2185d0;
			background: transparent none;
			font-weight: 400;
		}
	}
}
#tiers_json {
	margin: 0;
	background: #fff;
	border: 1px solid rgba(34, 36, 38, 0.15);
	outline: 0;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	box-shadow: 0 0 0 0 transparent inset;
	font-size: 1em;
	line-height: 1.2857;
}
.template-wrap-menu {
	display: flex;
	flex-wrap: wrap;
	width: 980px;
	max-width: 100%;
	@media (min-width: 576px) {
		width: 340px;
	}
	@media (min-width: 768px) {
		width: 340px;
	}
	@media (min-width: 1036px) {
		width: 980px;
	}
	@media (min-width: 1356px) {
		width: 1300px;
	}
	margin: 0 auto;
	padding-bottom: 20px;
	padding-right: 20px;
}

.howtoinstall {
	.main-step {
		margin-bottom: 20px;
		.title {
			font-size: 18px;
			font-weight: 700;
		}
	}
}
.help {
	.main-step {
		color: #000;
		margin-bottom: 10px !important;
		.title {
			font-size: 16px;
			font-weight: 700;
		}
		a {
			color: #000;
		}
	}
}
.dashboard-table {
	padding: 0 !important;
	overflow: hidden;
}

.lang-modal {
	display: flex;
	@media screen and (max-width: 480px) {
		display: grid;
	}
}
.main-payments {
	display: flex;
	margin-bottom: 20px;
	border-bottom: 1px solid gray;
}
.firms-payments {
	width: 50%;
	margin-bottom: 20px;
	.firms-box {
		padding: 10px;
		background-color: rgb(212, 211, 211);
	}
}

//roof
#yt-roof {
	text-align: center;
	.step-1 {
		display: flex;
		.input {
			flex: 1;
		}
	}

	#root > .container {
		margin-top: 20px;
	}
	.query_config {
		display: flex;
		width: 100%;
	}
	.loading-icon {
		margin-top: 10px !important;
	}

	.ui.container {
		text-align: center;
	}

	.ymaps-2-1-79-map {
		width: 390px !important; //450
		height: 390px !important;
	}

	#map-basics {
		display: inline-block;
		text-align: center;
		width: 390px; //457
		height: 390px;
	}

	.ymaps-2-1-79-map.ymaps-2-1-79-i-ua_js_yes.ymaps-2-1-79-map-bg.ymaps-2-1-79-islets_map-lang-ru {
		width: 390px !important;
		height: 390px !important;
	}
}

.step-2 {
	&.zoom-in {
		canvas {
			width: 500px;
		}
	}
	.canvas-wrap {
		.canvas-section {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
			margin-bottom: 20px;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.labels {
			display: flex;
			.input {
				margin: 0 3px;
			}
		}
	}
	canvas {
		//border: 3px solid black;
		//box-shadow: 0 0 12px 4px rgb(34 36 38 / 15%);
		margin: 20px;
	}
}
.multi-device {
	width: 30px;
	img {
		width: 25px;
	}
}
@media (max-width: 400px) {
	.only-web {
		display: none !important;
	}
}
@media (max-width: 400px) {
	.multi-device {
		width: 25px;
		img {
			width: 20px;
		}
	}
}
.test_send {
	margin-top: auto !important;
	margin-bottom: 1px !important;
}

//last

#messageBar {
	opacity: 1;
	background: #e1ffc7;
	border-radius: 5px 5px 5px 5px;
	padding: 0px 9px 0px 5px;
	float: right;
	margin: 10px 10px 10px 10px;
	padding-bottom: 2px;
	word-wrap: break-word;
}
.barOther {
	width: 88%;
}
.barPix {
	width: 266px;
}

.message.sent .message.sent {
	padding-left: 5px;
}
#messageBar:after {
	border-width: 0px 0 10px 10px;
	border-color: transparent transparent transparent #e1ffc7;
	top: 0;
	right: -10px;
}

.metadata {
	display: flex;
	float: right;
	padding: 0 0 0 7px;
	position: relative;
	bottom: 6px;
}

.metadata .tick {
	display: flex;
	margin-left: 2px;
	position: relative;
	top: 4px;
	height: 16px;
	width: 16px;
}

.metadata .tick svg {
	position: absolute;
	transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: perspective(800px) rotateY(180deg);
	transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: perspective(800px) rotateY(0deg);
	transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
	-webkit-transform: perspective(800px) rotateY(0);
	transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
	-webkit-transform: perspective(800px) rotateY(-179.9deg);
	transform: perspective(800px) rotateY(-179.9deg);
}

#preview {
	width: 100%;
	background: url(https://cdnydm.com/media/YGHtKNHmT577ScPMhy455w.png);
	background-color: #e5ddd5;
	border-style: groove;
}
.preModal {
	height: 750px;
}

.menuModal {
	height: 100%;
}

.modalImg {
	max-width: 380px;
	width: 100%;
	display: block;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 5px;
	border-radius: 10px;
}

.modalVideo {
	max-width: 380px;
	display: block;
	padding-bottom: 10px;
	padding-left: 5px;
	border-radius: 10px;
}

.documentImage {
	max-width: 75px;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 5px;
	border-radius: 10px;
}
